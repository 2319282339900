<template>
  <v-container id="supplements" fluid tag="section" class="container-full-size">
    <v-data-table
      :headers="headers"
      :items="supplements"
      :items-per-page="tableItemsPerPage"
      :page="currentPage"
      :search="search"
      :no-data-text="str['supplements_empty']"
      :no-results-text="str['supplements_empty']"
      class="elevation-1"
      @click:row="openEditSupplement"
      @update:page="(value) => (currentPage = value)"
      @update:items-per-page="(value) => (tableItemsPerPage = value)"
    >
      <template #top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="str['search']" single-line hide-details />
          <v-spacer />
          <v-dialog v-model="dialogSupplement" persistent max-width="750px">
            <template #activator="{ on, attrs }">
              <v-btn v-if="editPermission" color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="openNewSupplement()">
                {{ str['add'] }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span v-if="!supplementToEdit" class="headline">
                  {{ str['new_supplement'] }}
                </span>
                <span v-if="supplementToEdit" class="headline">
                  {{ str['edit_supplement'] }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in data"
                      :key="item.id"
                      cols="12"
                      :style="{
                        'pointer-events': editPermission ? '' : 'none',
                      }"
                    >
                      <v-text-field
                        v-if="item.type === 'input'"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                      />

                      <div v-if="item.type === 'image'">
                        <div>
                          {{ str['image'] }}
                        </div>
                        <div v-if="item.value" class="form-field-supplement-image">
                          <input id="supplements-input-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewImage" />
                          <v-img contain :src="item.value" alt="Suplemento" width="300px" height="300px" />
                        </div>
                        <div v-if="!item.value && editPermission" class="form-field-supplement-image">
                          <input id="supplements-input-image" type="file" accept=".png,.jpg,.jpeg" @change="addNewImage" />
                          <v-btn class="mx-2" fab dark small color="success">
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </div>
                      </div>

                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :no-data-text="str['no_data']"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn color="default" @click="closeSupplement">
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn v-if="editPermission" color="success" @click="saveSupplement">
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.image="{ item }">
        <div class="p-2 text-center" style="display: flex; justify-content: center; padding: 10px 0">
          <v-img contain :src="item.image" :alt="item.name" width="60px" height="60px" />
        </div>
      </template>
      <template #item.actions="{ item }">
        <v-icon small class="mr-0" color="error" @click.stop="deleteSupplement(item)">mdi-delete</v-icon>
      </template>
      <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  data() {
    const user = Utils.getUser()
    const hasTranslations = user && user.configurations && user.configurations.food && user.configurations.food.has_translations
    const editPermission = Utils.hasPermission('supplement_list_edit')
    const headers = []
    const form = []

    headers.push({ text: window.strings['id'], value: 'id' })
    headers.push({
      text: window.strings['image'],
      value: 'image',
      sortable: false,
      align: 'center',
    })
    headers.push({ text: window.strings['name'], value: 'name', align: 'left' })
    headers.push({
      text: window.strings['description'],
      value: 'description',
      align: 'left',
    })
    headers.push({ text: window.strings['url'], value: 'url', align: 'center' })
    if (editPermission) {
      headers.push({
        text: window.strings['actions'],
        value: 'actions',
        sortable: false,
        align: 'center',
      })
    }

    form.push({
      type: 'input',
      id: 'name',
      title: window.strings['name'],
      value: '',
      visible: true,
      required: true,
    })
    if (hasTranslations) {
      hasTranslations.forEach(function (tr) {
        form.push({
          type: 'input',
          id: 'name_' + tr,
          title: window.strings['name'] + ' (' + tr.toUpperCase() + ')',
          value: '',
          visible: true,
          required: false,
        })
      })
    }

    form.push({
      type: 'input',
      id: 'description',
      title: window.strings['description'],
      value: '',
      visible: true,
    })
    if (hasTranslations) {
      hasTranslations.forEach(function (tr) {
        form.push({
          type: 'input',
          id: 'description_' + tr,
          title: window.strings['description'] + ' (' + tr.toUpperCase() + ')',
          value: '',
          visible: true,
        })
      })
    }

    form.push({
      type: 'input',
      id: 'url',
      title: window.strings['url'],
      value: '',
      visible: true,
    })

    form.push({
      type: 'image',
      id: 'image',
      title: window.strings['image'],
      value: '',
      visible: true,
    })

    return {
      str: window.strings,
      user: user,
      editPermission: editPermission,
      currentPage: 1,
      tableItemsPerPage: Utils.getTableCache('supplements', 'itemsPerPage') || 10,
      supplements: [],
      dialogSupplement: false,
      data: form,
      search: '',
      headers: headers,
      supplementToEdit: null,
      imageMaxSize: 300,
      supplementType: 100,
      imagesLoaded: false,
    }
  },
  watch: {
    dialogSupplement(val) {
      const dialog = document.getElementsByClassName('v-dialog')
      if (dialog && dialog[0]) {
        dialog[0].scrollTo(0, 0)
      }
      val || this.closeSupplement()
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getSupplements(true)
  },
  beforeDestroy: function () {
    Utils.setTableCache('supplements', 'itemsPerPage', this.tableItemsPerPage)
    Utils.setTableCache('supplements', 'page', this.currentPage)
  },
  methods: {
    getSupplements: function (getCachePage) {
      const self = this
      Api.getContents(
        {
          type: this.supplementType,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].image = require('@/assets/loading.gif')
            }
            self.supplements = response.data
            self.getSupplementsImages()
            if (getCachePage) {
              self.currentPage = Utils.getTableCache('supplements', 'page') || self.currentPage
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getSupplementsImages: function () {
      const self = this
      Api.getContents(
        {
          type: this.supplementType,
          photo: true,
          fields: ['id', 'image'],
        },
        function (response) {
          if (response.success) {
            const ids = []
            for (let i = 0; i < self.supplements.length; i++) {
              ids[self.supplements[i].id] = {
                index: i,
              }
            }
            for (let i = 0; i < response.data.length; i++) {
              if (ids[response.data[i].id]) {
                self.supplements[ids[response.data[i].id].index].image = response.data[i].image
              }
            }
            self.imagesLoaded = true
            if (self.supplementToOpenAfterImagesLoaded) {
              self.openEditSupplement(self.supplementToOpenAfterImagesLoaded)
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openNewSupplement() {
      this.setData()
      this.supplementToEdit = null
      this.dialogSupplement = true
    },
    openEditSupplement(supplement) {
      if (this.imagesLoaded) {
        this.$isLoading(false)
        this.setData(supplement)
        this.supplementToEdit = supplement
        this.dialogSupplement = true
      } else {
        this.$isLoading(true)
        this.supplementToOpenAfterImagesLoaded = supplement
      }
    },
    setData: function (supplement) {
      for (let i = 0; i < this.data.length; i++) {
        if (supplement) {
          this.data[i].value = supplement[this.data[i].id]
        } else {
          this.data[i].value = ''
        }
      }
    },
    closeSupplement() {
      this.supplementToEdit = null
      this.dialogSupplement = false
    },
    saveSupplement() {
      const self = this
      const data = this.getData()

      if (data) {
        this.$isLoading(true)

        if (data.id) {
          Api.updateContent(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeSupplement()
              for (let i = 0; i < self.supplements.length; i++) {
                if (self.supplements[i].id === data.id) {
                  self.$set(self.supplements, i, response.data)
                  break
                }
              }
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        } else {
          Api.newContent(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              self.closeSupplement()
              self.supplements.push(response.data)
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          })
        }
      }
    },
    getData: function () {
      const data = {}
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].required && !this.data[i].value && this.data[i].type !== 'select') {
          this.$alert(window.strings['fields_to_be_filled'], '', 'warning', Utils.getAlertOptions())
          return false
        }
        data[this.data[i].id] = this.data[i].value
      }

      data.status = 0
      data.type = this.supplementType

      if (this.supplementToEdit) {
        data.id = this.supplementToEdit.id
      }

      return data
    },
    deleteSupplement(supplement) {
      const self = this
      const supplementId = supplement.id

      this.$confirm(
        window.strings['want_delete_supplement'] + (supplement ? ' "' + supplement.name + '"' : '') + '?',
        '',
        'warning',
        Utils.getAlertOptions(true, true),
      )
        .then(() => {
          self.$isLoading(true)
          Api.deleteContent(
            {
              id: supplementId,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                for (let i = 0; i < self.supplements.length; i++) {
                  if (self.supplements[i].id === supplementId) {
                    self.supplements.splice(i, 1)
                    break
                  }
                }
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    addNewImage(event) {
      const self = this
      const file = event.srcElement.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = function (e) {
        const image = new Image()
        image.src = reader.result

        image.onload = function () {
          if (image.width < self.imageMaxSize && image.height < self.imageMaxSize) {
            self.saveNewImage(e.target.result)
          } else {
            self.saveNewImage(resizeImage(image))
          }
        }
      }
      reader.onerror = function () {}

      function resizeImage(image) {
        const canvas = document.createElement('canvas')
        let width = image.width
        let height = image.height

        if (width > height) {
          if (width > self.imageMaxSize) {
            height *= self.imageMaxSize / width
            width = self.imageMaxSize
          }
        } else {
          if (height > self.imageMaxSize) {
            width *= self.imageMaxSize / height
            height = self.imageMaxSize
          }
        }
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, width, height)
        return canvas.toDataURL()
      }
    },
    saveNewImage(image) {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === 'image') {
          this.data[i].value = image
          this.$set(this.data, i, this.data[i])
          break
        }
      }
      document.getElementById('supplements-input-image').value = ''
    },
  },
}
</script>
